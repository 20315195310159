import { Route } from '@angular/router';
import { authenticated, unauthenticated } from 'auth';

export const appRoutes: Route[] = [
  {
    path: 'auth',
    canActivate: [unauthenticated('/')],
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '',
    canActivate: [authenticated('/auth')],
    loadChildren: () =>
      import('./modules/dashboard/dashboard.module').then(
        (m) => m.DashboardModule,
      ),
  },

  { path: '**', redirectTo: '' },
];
